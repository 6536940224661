const parsedTelefone = (process.env.MIX_TELEFONE === 'true');
window.realtime = (parsedTelefone) ? io.connect(`${location.protocol}//${location.hostname}:3006/`) : { 'on': function(){}, 'emit': function(){} };


$('#behindHeader').css('height', $('#header').outerHeight());
$(window).on('resize', function() {
    $('#behindHeader').css('height', $('#header').outerHeight());
})

// Datatables Default Settings
$.extend($.fn.dataTable.defaults, {
    deferRender: true,
    scrollX: true,
    // scroller: true,
    order: [
        [0, "desc"]
    ],
    dom: "<'row'<'col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6'B><'col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6'f>>" +
        "<'row'<'col-sm-12'r>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
    buttons: [{
            extend: 'excelHtml5',
            text: '<i class="fas fa-file-excel"></i> <span class="d-none d-sm-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex">Excel</span>',
            exportOptions: {
                orthogonal: 'export'
            }
        },
        {
            extend: 'pdfHtml5',
            text: '<i class="fas fa-file-pdf"></i> <span class="d-none d-sm-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex">PDF</span>',
            exportOptions: {
                orthogonal: 'export'
            }
        },
        { extend: 'pageLength' },
        {
            text: '<i class="fas fa-sync-alt"></i> <span class="d-none d-sm-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex">Recarregar</span>',
            action: function(e, dt, node, config) {
                $('#tableLoading').show();
                dt.ajax.reload(null, false);
            },
            attr: { class: 'btn btn-primary dtUpdateButton', disabled: true }
        }
    ],
    lengthMenu: [
        [10, 25, 50, -1],
        ['Exibir 10 linhas', 'Exibir 25 linhas', 'Exibir 50 linhas', 'Exibir todas linhas']
    ],
    language: {
        sEmptyTable: "Nenhum registro encontrado",
        sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
        sInfoFiltered: "(Filtrados de _MAX_ registros)",
        sInfoPostFix: "",
        sInfoThousands: ".",
        sLengthMenu: "_MENU_ resultados por página",
        sLoadingRecords: "Carregando...",
        sProcessing: "Processando...",
        sZeroRecords: "Nenhum registro encontrado",
        sSearch: "Pesquisar",
        oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último"
        },
        buttons: {
            pageLength: {
                _: "<i class='fas fa-table fa-fw'></i> <span class='d-none d-sm-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex'>Exibindo %d linhas</span>",
                '-1': "<i class='fas fa-table fa-fw'></i> <span class='d-none d-sm-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex'>Exibindo todas as linhas</span>"
            }
        },
        oAria: {
            sSortAscending: ": Ordenar colunas de forma ascendente",
            sSortDescending: ": Ordenar colunas de forma descendente"
        }
    },
    preDrawCallback: function(settings) {
        var api = new $.fn.dataTable.Api(settings);
        var dtId = api.tables().nodes().to$().attr('id');
        $('#' + dtId).closest('div .alert').attr('hidden', false);
    },
    drawCallback: function(settings) {
        var api = new $.fn.dataTable.Api(settings);
        var dtId = api.tables().nodes().to$().attr('id');
        $('#' + dtId).closest('div .alert').attr('hidden', true);
    }
});

// Datatables LIVE DOM
$.fn.dataTable.ext.order['dom-text'] = function(settings, col) {
    return this.api().column(col, { order: 'index' }).nodes().map(function(td, i) {
        return $('input', td).val();
    });
}

/* Create an array with the values of all the input boxes in a column, parsed as numbers */
$.fn.dataTable.ext.order['dom-text-numeric'] = function(settings, col) {
    return this.api().column(col, { order: 'index' }).nodes().map(function(td, i) {
        return $('input', td).val() * 1;
    });
}

/* Create an array with the values of all the select options in a column */
$.fn.dataTable.ext.order['dom-select'] = function(settings, col) {
    return this.api().column(col, { order: 'index' }).nodes().map(function(td, i) {
        return $('select', td).val();
    });
}

/* Create an array with the values of all the checkboxes in a column */
$.fn.dataTable.ext.order['dom-checkbox'] = function(settings, col) {
    return this.api().column(col, { order: 'index' }).nodes().map(function(td, i) {
        return $('input', td).prop('checked') ? '1' : '0';
    });
}

/* Menu style function */
$("#menuButton").on("click", function() {
    this.classList.toggle("change");
    if ($(this).hasClass("change")) {
        $("#menu").attr("hidden", false);
        $('body').css({ 'overflow': 'hidden' });
    } else {
        $("#menu").attr("hidden", true);
        $('body').css({ 'overflow': 'auto' });
    }
});

$('[data-popover="true"]').popover({
    html: true,
});

// tv switcher
$('#tvSwitcher').on('click', function(e) {
    $('#page-container').toggleClass('container container-fluid pt-3')
    $('#page-title').attr('hidden', function(index, attr) {
        return attr == 'hidden' ? false : true;
    })
    $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();
})

// tabs
$("#tabs, .tabs").tabs();

$('#tabs, .tabs').on('click', function(e) {
    $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();
});

$('.modal').on('shown.bs.modal', function(e) {
    $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();
});

$(document).on('show.bs.modal', '.modal', function(event) {
    var zIndex = 1040 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function() {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
});

// Release and Occurrence DT
var dtRreleaseList = $('#dtMyReleases').DataTable({
    ajax: {
        url: 'release/listRelease',
        dataType: 'json',
        type: 'GET',
        dataSrc: ''
    },
    rowId: 'id',
    searching: false,
    columns: [
        { data: 'communicated_name' },
        { data: 'date_start', render: $.fn.dataTable.render.moment('YYYY-MM-DD HH:mm', 'DD/MM/YYYY - HH:mm') },
        { data: 'date_end', render: $.fn.dataTable.render.moment('YYYY-MM-DD HH:mm', 'DD/MM/YYYY - HH:mm') },
        { data: null, defaultContent: '<i class="fas fa-search-plus"></i>', className: 'text-center showMore cursor-pointer', orderable: false }
    ],
    drawCallback: function(settings) {
        var api = this.api();
        var count = api.rows().data().length
        $('#badgeComunicados').text(count);
    }
}).buttons('.btn').remove();

$('#dtMyReleases').on('click', 'td.showMore', function() {
    var tr = $(this).closest('tr');
    var data = dtRreleaseList.row(tr).data();

    $('#messageContent .header .name').html(`<h4>${data.responsible}</h4>`);
    $('#messageContent .header .photo').html(() => {
        var photo = (data.photo === null ? '/imgs/default-profile.png' : data.photo)
        var html = `<img src="${photo}" class="rounded-circle" width="58"></img>`
        return html;
    });
    $('#messageContent .header .meta').html(() => {
        var html = `<small>
                        <strong>Data de Início:</strong> 
                        ${moment(data.date_start).format('DD/MM/YYYY - HH:mm')} 
                        | 
                        <strong>Data de Fim:</strong> 
                        ${moment(data.date_end).format('DD/MM/YYYY - HH:mm')}
                    </small>`
        return html;
    });
    $('#messageContent .text').html(data.message);
    $('#messageModal').modal('show');
})